export default [
	{
		path     : '/',
		name     : 'HomeView',
		meta     : {
			keepAlive: true
		},
		component: () => import('@/views/Home/HomeView.vue')
	},
	{
		path     : '/aboutus',
		name     : 'aboutus',
		component: () => import('@/views/Home/AboutView.vue')
	},
	{
		path     : '/course',
		name     : 'course',
		component: () => import('@/views/Home/CourseView.vue')
	},
	{
		path     : '/model',
		name     : 'model',
		component: () => import('@/views/Home/ModelView.vue')
	},
	{
		path     : '/vr',
		name     : 'vr',
		component: () => import('@/views/Home/VrView.vue')
	},
	{
		path     : '/model/chat',
		name     : 'model_chat',
		component: () => import('@/views/Model/chat.vue')
	},
	{
		path     : '/agreement',
		name     : 'agreement',
		component: () => import('@/views/Home/AgreementView.vue')
	},
	{
		path     : '/privacy',
		name     : 'privacy',
		component: () => import('@/views/Home/PrivacyView.vue')
	},
]
import Vue from 'vue'
import VueRouter from 'vue-router'

//导入其他路由文件
import base from "@/router/libs/base";
import home from "@/router/libs/home";

Vue.use(VueRouter)

const routes = [
	...base,
	...home
]

const router = new VueRouter({
	mode: 'history',
	routes
})

export default router
